<template>
<div>
	<CRow>
		<CCol sm="6" md="12">
        <CCard>
          <CCardHeader>
            <h4>P2P Background Image</h4> 
            <sub-title>Required dimension (width*height) : 640 x 940</sub-title>     
          </CCardHeader>
          <CCardBody> 
              <CForm>
              <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
              </CRow>
              <CRow>
                  <CCol col="12" class="text-center">                   
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                  </CCol> 
              </CRow> 

              <input type="file" :v-model="srcFile" ref="myFile"  @change="onFileChanged"> 
              <CButton @click="onUpload" color="primary">Upload</CButton>

              <div v-if="imageLoaded">
                <!-- Image size is {{image.size}}<br/> -->
                <!-- {{image.width}} * {{image.height}} -->
              </div>
              
              </CForm>

          </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script> 
  import TrService from '@/api/TransactService.js';

  export default {    
    data() {
      return { 
          id : this.$route.params.id,
          image:{
            size:0,
            height:0,
            width:0
          },
          imageLoaded:false,
          srcFile:'',
          selectedFile: null,
          seen:false,
          msg :'',
          color:'',
          spinner:false,
        };      
      
    },
    methods: {
      onFileChanged (event) {
          this.selectedFile = event.target.files[0];
      },

      async actionSubmit(){

               if(this.image.width === 640 && this.image.height === 940) {  
                    const formData = new FormData()
                    formData.append('resource', this.selectedFile, this.selectedFile.name);
                    try {                     
                        const resp = await TrService.uploadBGImage(this.id,formData);  //console.log(resp); 
                        if(resp.message==="Success"){                  
                          this.msg   = "Upload Image Success!"; 
                          this.color = 'success'; this.seen  = true;  
                          //this.image.width=0; this.image.height=0;            
                        } else {
                          this.msg   =  !resp.data.message ? "Upload Image Failed!" : resp.data.message; 
                          this.color = 'danger'; this.seen  = true;  
                          //this.image.width=0; this.image.height=0;
                        }
                        setTimeout( () =>  this.seen=false , 5000);
                    } catch (error) {                
                        console.log(error);
                    }
                } else { 
                  this.msg  = "Failed, detected dimension " + this.image.width +" * "+ this.image.height; 
                  this.color = 'warning'; this.seen  = true;  
                  setTimeout( () =>  this.seen=false , 5000);
                };

                this.spinner = false;

      },
     
      async onUpload() { 

          this.spinner = true;
          this.imageLoaded = false;
          let file = this.$refs.myFile.files[0];

          if(!file || file.type.indexOf('image/') !== 0) return;
      
          this.image.size = file.size;          
          let reader = new FileReader();
          
          reader.readAsDataURL(file);
          reader.onload = evt => {
            let img = new Image();
            img.onload = () => {
              this.image.width  = img.width;
              this.image.height = img.height;
              this.imageLoaded  = true; 
            }
            img.src = evt.target.result;
          }
          reader.onerror = evt => { console.error(evt); }          

          setTimeout( () =>  this.actionSubmit() , 5000); 

      }     
      
    }
  }
</script>